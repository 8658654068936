<template>
    <div class="plan">
        <div class="container">
            <div class="row">
                <div class="col-sm-3 width-product-card" v-for="product in wideHouse" :key="product.id">
                    <a @click="goToProductDetail(product.slug)"><img v-if="product._embedded['wp:featuredmedia']" v-lazy="{src: product._embedded['wp:featuredmedia'][0].source_url}" class="img-wp-thumbnail" alt=""></a>
                    <div class="content-product-card">
                        <a @click="goToProductDetail(product.slug)" class="router-home"><p class="title-pd-card">{{ product.title.rendered }}</p></a>
                        <div class="detail-pd-card" v-html="product.excerpt.rendered"></div>
                        <a @click="goToProductDetail(product.slug)" class="router-home"><p class="cart"><i class="fa fa-shopping-cart"></i>&nbsp;&nbsp;{{ $t('product.see') }}</p></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    computed: {
        wideHouse() {
            return this.$store.state.wideHouse;
        },
    },
    created() {
        this.$store.dispatch("getWideHouse");
    },
    methods: {
        goToProductDetail(slug) {
            window.scrollTo(0,0)
            this.$router.push ({name: 'product-detail', params:{slug:slug}})
        },
    }
}
</script>